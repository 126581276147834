import * as React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

export default function LabTabs(props) {
  const [value, setValue] = React.useState('1')

  let vimiva = props.api

  const vimivaData = async () => {
    const response = await axios.get(vimiva)
    const { data } = await response
    SetMissionTitle(data[0].misson_title)
    SetMissionDesc(data[0].misson_description)
    SetMissionImage(data[0].misson_image)

    SetVisionTitle(data[0].values_title)
    SetVisionDesc(data[0].values_description)
    SetVisionImage(data[0].vision_image)

    SetValuesTitle(data[0].vision_title)
    SetValuesDesc(data[0].vision_description)
    SetValuesImage(data[0].values_image)
  }

  const [missionTitle, SetMissionTitle] = React.useState('')
  const [missionDesc, SetMissionDesc] = React.useState('')
  const [missionImage, SetMissionImage] = React.useState('')

  const [visionTitle, SetVisionTitle] = React.useState('')
  const [visionDesc, SetVisionDesc] = React.useState('')
  const [visionImage, SetVisionImage] = React.useState('')

  const [valuesTitle, SetValuesTitle] = React.useState('')
  const [valuesDesc, SetValuesDesc] = React.useState('')
  const [valuesImage, SetValuesImage] = React.useState('')

  React.useEffect(() => {
    vimivaData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const { i18n } = useTranslation()

  const { t } = useTranslation()
  const dir = {
    direction: `${i18n.dir()}`,
  }

  return (
    <Box
      className="vismisval"
      style={dir}
      sx={{
        width: '100% !important',
        typography: 'body1 !important',
        textAlign: 'center !important',
        mt: '30px !important',
        fontSize: '1rem !important',
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={t('mission')} value="1" />
            <Tab label={t('vision')} value="2" />
            <Tab label={t('values')} value="3" />
          </TabList>
        </Box>
        <TabPanel className="conMui" value="1">
          <img src={missionImage} alt="logo" />
          <div className="missionVisionValues mission">
            <h1>{missionTitle}</h1>
            <p align="justify">{missionDesc}</p>
          </div>
        </TabPanel>
        <TabPanel className="conMui" value="2">
          <img src={visionImage} alt="logo" />
          <div className="missionVisionValues vision">
            <h1>{visionTitle}</h1>
            <p align="justify">{visionDesc}</p>
          </div>
        </TabPanel>
        <TabPanel className="conMui" value="3">
          <img src={valuesImage} alt="logo" />
          <div className="missionVisionValues values">
            <h1>{valuesTitle}</h1>
            <p align="justify">{valuesDesc}</p>
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
