import React, { useMemo, useState } from 'react';
import './MyOrders.scss';
import order from '../../Images/package.png'
import { useNavigate } from "react-router-dom";
import {t} from 'i18next';
 import { useTranslation } from 'react-i18next';
import Loader from '../Loader';

 

const MyOrders = (props) => {
    const navigate = useNavigate()
    const { i18n } = useTranslation();
 

    const [myOrders, setMyOrders] = useState()

    let api = props.Api


    let token = window.localStorage.getItem('userToken')

    const MyOrdersData = async () => {

        const response = await fetch(api, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })

        const data = await response.json()
        setMyOrders(data.order_details);
    }



    useMemo(() => {
        MyOrdersData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

     const checkPoint = myOrders?.length

    if (checkPoint === 0) {
        return (
            <div className='emptyOrders'>{t('emptyOrders')}</div>)
    }


    const navToDetails = (e) => {
        navigate(`/OrderDetails/${e.currentTarget.dataset.orderid}`)
    }
    

    const dir = {
        direction: `${i18n.dir()}`
    }

    return (
        <section style={dir} id="myOrdSection">
            <Loader />
            <div className='allOrdersContainer'>

                {myOrders?.map((data, index) => (
                    


                        <div key={index} className='orderContainer' data-orderid={index} onClick={navToDetails} >
                            <div className='orderImgContainer'>
                                <img className='orderImg' src={order} alt="Order" />
                            </div>
                            <span className='orderId'>#{data.order_number}</span>
                            <div>
                                <h6>{t('DeleveriyMethod')}[{data.PickupType}]</h6>
                                <h6>{t('PaymentMethod')} [{data.paymentMethod}]</h6>
                                <h6>{t('OrderId')} [{data.order_id}]</h6>

                            </div>
                            <div>
                            </div>

                        </div>

                 ))}
            </div>





        </section>
    )
}

export default React.memo(MyOrders)