import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

const initialState = {
  cartItems: localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems'))
    : [],
  cartTotalQuantity: 0,
  cartTotalDiscount: 0,
  cartTotalAmount: 0,
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, action) {
      const existingIndex = state.cartItems.findIndex(
        (item) => item.orderId === action.payload.orderId
      )

      if (existingIndex >= 0) {
        state.cartItems[existingIndex] = {
          ...state.cartItems[existingIndex],
          cartQuantity: state.cartItems[existingIndex].cartQuantity + 1,
        }

        toast.info('Increased product quantity', {
          position: 'bottom-left',
        })
      } else {
        let tempProductItem = { ...action.payload, cartQuantity: 1 }
        state.cartItems.push(tempProductItem)
        toast.success('Product added to cart', {
          position: 'bottom-left',
        })
      }
      window.localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    },
    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.orderId === action.payload.orderId
      )

      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1

        toast.info('Decreased product quantity', {
          position: 'bottom-left',
        })
      } else if (state.cartItems[itemIndex].cartQuantity === 1) {
        const nextCartItems = state.cartItems.filter(
          (item) => item.orderId !== action.payload.orderId
        )

        state.cartItems = nextCartItems

        toast.error('Product removed from cart', {
          position: 'bottom-left',
        })
      }

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    },

    removeFromCart(state, action) {
      state.cartItems.map((cartItem) => {
        if (cartItem.orderId === action.payload.orderId) {
          const nextCartItems = state.cartItems.filter(
            (item) => item.orderId !== cartItem.orderId
          )

          state.cartItems = nextCartItems

          toast.error('Product removed from cart', {
            position: 'bottom-left',
          })
        }
        window.localStorage.setItem(
          'cartItems',
          JSON.stringify(state.cartItems)
        )
        return state
      })
    },

    getTotals(state, action) {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { item_price, cartQuantity, Discount_price, addsTotal } =
            cartItem
          console.log(addsTotal)
          const itemTotal =
            Discount_price === 'No Discount'
              ? (parseFloat(item_price) + addsTotal) * cartQuantity
              : (parseFloat(Discount_price) + addsTotal) * cartQuantity
          cartTotal.total += itemTotal
          cartTotal.addsTotal += addsTotal
          cartTotal.quantity += cartQuantity

          return cartTotal
        },
        {
          total: 0,
          quantity: 0,
        }
      )
      total = parseFloat(total)

      state.cartTotalQuantity = quantity
      state.cartTotalAmount = total
    },
    clearCart(state, action) {
      state.cartItems = []

      window.localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
      toast.error('Cart cleared', { position: 'bottom-left' })
    },
  },
})

export const {
  addToCart,
  decreaseCart,
  removeFromCart,
  getTotals,
  clearCart,
  payMentMethod,
} = cartSlice.actions

// Export Cartn State from here to just import getAllCart
export const getAllCart = (state) => state.cart

export default cartSlice.reducer
