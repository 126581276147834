import React, { useState, useMemo } from 'react'
import { useParams } from "react-router";
import './OrderDetails.scss';
import check from '../../Images/check-button.png';
import canceld from '../../Images/canceld.png';
import pending from '../../Images/pending.png';
import accepted from '../../Images/accepted.png';
import prepare from '../../Images/prepare.png';
import ondelevery from '../../Images/ondelevery.png';
import Loader from '../Loader'
import { t } from 'i18next';


const OrderDetails = (props) => {
    const params = useParams();
    const [myOrders, setMyOrders] = useState()
    const [tax, setTax] = useState()
    const [deliveryFee, setDeliveryFee] = useState()
    const [totalQty, setTotalQty] = useState()
    const [branch, setBranch] = useState()
    const [total_Amount, setTotal_Amount] = useState()
    const [statues, setStatues] = useState(0)
    const [loading, setLoading] = useState(true);

    let api = props.Api


    let token = window.localStorage.getItem('userToken')

    const MyOrdersData = async () => {
        const response = await fetch(api, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token
            }
        })

        const data = await response.json()

        setMyOrders(data.order_details[params.id].item);


        setTotal_Amount(data.order_details[params.id].Total_Amount);
        setTax(data.order_details[params.id].tax);
        setDeliveryFee(data.order_details[params.id].deliveryFee);
        setTotalQty(data.order_details[params.id].totalQty);
        setBranch(data.order_details[params.id].branch);

        setStatues(data.order_details[params.id].status);
        setLoading(false);




    }



    useMemo(() => {
        MyOrdersData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        loading ? (<Loader />) : (
        <div className='bigContainerForItems'>

           
                <div className='tableFooter'>
                    <h5>{t('Item')}</h5>
                    <h5>{t('ItemName')}</h5>
                    <h5>{t('ItemPrice')}</h5>
                     <h5>{t('Quantity')}</h5>
                </div>

                {myOrders?.map((data, index) => (

                    <div className='OrderDetailsContainer' key={index}>
                        <div className='itemBackGroundContainer'>
                            <img src={data.item_image} alt="Fauzan glasses" />
                        </div>
                        <small>{data.item_name}</small>
                        <small>{data.itemPrice}</small>
                        <small>{data.quantity}</small>


                    </div>

                ))}

                <hr className='hr' />

                <div className='lastLine'>

                    <div className='tableFooter'>
                        <div class="vl"></div>

                        <h5>{t('DeleveriyFee')}</h5>
                        <div class="vl"></div>

                        <h5>{t('TotalQuantity')}</h5>
                        <div class="vl"></div>

                        <h5>{t('BranchName')}</h5>
                        <div class="vl"></div>

                        <h5>{t('Tax')}</h5>
                        <div class="vl"></div>

                        <h5>{t('TotalPrice')}</h5>
                        <div class="vl"></div>

                    </div>

                    <div className='tableFooter'>
                        <h5>{deliveryFee}</h5>
                        <h5>{totalQty}</h5>
                        <h5>{branch}</h5>
                        <h5>{tax}%</h5>
                        <h5>{total_Amount}</h5>
                    </div>

                </div>







                {statues === "5" ? (
                    <div className='statuesContainer'>
                        <img className='statues' src={check} alt="Fauzan glasses" />
                        <br />
                        <bold>{t("Delevered")}</bold>
                    </div>
                ) : statues === "6" ? (
                    <div className='statuesContainer'>
                        <img className='statues' src={canceld} alt="Fauzan glasses" />
                        <br />
                        <bold>{t('Rejected')}</bold>
                    </div>
                ) : statues === "1" ? (
                    <div className='statuesContainer'>
                        <img className='statues' src={pending} alt="Fauzan glasses" />
                        <br />
                        <bold>{t('Pending')}</bold>
                    </div>
                ) : statues === "2" ? (
                    <div className='statuesContainer'>
                        <img className='statues' src={accepted} alt="Fauzan glasses" />
                        <br />
                        <bold>{t('Accepted')} </bold>
                    </div>
                ) : statues === "3" ? (
                    <div className='statuesContainer'>
                        <img className='statues' src={prepare} alt="Fauzan glasses" />
                        <br />
                        <bold>{t('Prepare')}  </bold>
                    </div>
                ) : statues === "4" ? (
                    <div className='statuesContainer'>
                        <img className='statues' src={ondelevery} alt="Fauzan glasses" />
                        <br />
                        <bold>{t('OnDelevery')}</bold>
                    </div>
                ) : ""}
         </div>
        )
    )
}

export default OrderDetails