import React from 'react'
import s from '../style.module.scss'
import { NavLink } from 'react-router-dom'

export default function BrandCard({ name, image, id }) {
  return (
    <NavLink to={'/BrandItems/' + id}>
      <div className={s.card}>
        <img className={s.img} src={image} alt={name} />
        <h2 className={s.name}>{name}</h2>
      </div>
    </NavLink>
  )
}
