import { createSlice } from '@reduxjs/toolkit'



const initialState = {
    data: [],
}

const favSlice = createSlice({
    name: "fav",
    initialState,
    reducers: {
        addToFavorit(state, action) {

            console.log("___________________________");

            console.log(action.payload.productID);

            console.log("___________________________");
            console.log(action.payload.productState);

            console.log("___________________________");
        },




    },

});

export const { addToFavorit } = favSlice.actions;

export default favSlice.reducer;