import React from 'react'
import MapStore from './MapStore.js';
import './Branches.scss'
import { t } from 'i18next';
import branhesImage from '../../Images/branches.png'
import { useTranslation } from 'react-i18next';
import Loader from '../Loader.js';

const Branches = (props) => {
    const { i18n } = useTranslation();

    const dir = {
        direction: `${i18n.dir()}`

    }

    return (
        <>
            <Loader />
        <h1 id='contactUsHead'>{t('branches')}</h1>
        <div style={dir} className='branchesContainer'>


            <img src={branhesImage} alt="instyle" />
            <MapStore Api={props.Api} />


        </div>
        </>
    )
}

export default Branches