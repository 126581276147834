import React from 'react';
import './Favorates.scss';
import '../Products/Products.scss'
import { useSelector } from "react-redux";
 
import Loader from '../Loader';
import style from '../Products/items.module.scss'
 
import FavCards from './FavCards';

const FavItems = () => {

 
    const productsData = useSelector((state) => state.products);
    


   

    return (
        <div className={style.productsContainer}>
        <Loader />
            {productsData.items?.filter(number => number.fav_item === 1)?.map((product, index) => (
                      <FavCards product={product}/>


            )


            )




            }






</div>
    )

}

export default React.memo(FavItems)