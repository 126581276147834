import React, { useEffect, useState } from 'react'
import './Profile.scss'
// import PhoneInput from 'react-phone-number-input'
import { useNavigate } from "react-router-dom";
import Loader from '../Loader.js';

import axios from 'axios'


const Profile = (props) => {
    let api = props.Api
    // const [phone, setPhoneNum] = useState()

    const [dPhone, setDphone] = useState()
    const [dUser, setDUser] = useState()

 





    let token = window.localStorage.getItem('userToken')



    const getUserData = async () => {
        const response = await axios.get(api, {
            headers: {

                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + token,
            }
        })
        const { data } = await response
        setDphone(data.reply[0].phone_number)
        setDUser(data.reply[0].firstName)
 
    }

    useEffect(() => {
        getUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    let navigate = useNavigate();
    const NavToUpdate = () => {
        navigate("/UpdateProfile");


    }

    const logOut = () => {
        navigate("/");
        window.localStorage.clear();

    }


    return (
            <div id='userInfo'>
                <Loader />
                <div id='insideUserInfo'>


                    <input
                        className="userNameProfile"
                        type="text"
                        placeholder={dPhone}
                        disabled


                    />
                    <input
                        className="userNameProfile"
                        type="text"
                        placeholder={dUser}
                        disabled


                    />

                    <div className="buttonsContainer">
                        <button className="btn-update" onClick={NavToUpdate}>
                            Update
                        </button>
                        <button className="btn-update" onClick={logOut}>
                            Log out
                        </button>
                    </div>

                </div>


            </div>
    )
}

export default Profile