import { Outlet, Navigate } from "react-router";


const PravateRoutes = () => {

    let token = window.localStorage.getItem('userToken');



    return (
        token !== null || undefined ? <Outlet /> : <Navigate to="/SignIn-SignUp" />

    )
}

export default PravateRoutes