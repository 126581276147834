import React, { useEffect, useState } from 'react'
import './PostsCard.scss'
import { t } from 'i18next'
import Loader from '../Loader'
import facebook from '../../Images/facebook.svg'
import instagram from '../../Images/instagram.svg'
import twitter from '../../Images/twitter.svg'

const BlogCard = (props) => {
  const api = props.api
  const [data, setData] = useState()

  const getPostsData = async () => {
    const response = await fetch(api)
    const data = await response.json()
    setData(data)
  }
  useEffect(() => {
    getPostsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  if (data?.length === 0)
    return <div className="emptyFav">{t('postsCard')}</div>

  return (
    <>
      <Loader />

      {data?.map((card) => (
        <div key={card.index} className="post-container">
          {card.media_type === 1 ? (
            <img className="image" src={card.media} alt="post" />
          ) : (
            <video width="100%" height="50%" controls>
              <source src={card.media} type="video/mp4" />
            </video>
          )}

          <h3 className="heading">{card.title}</h3>
          <p>{card.description}</p>
          <div id="btns-line">
            <div id="btns-social">
              <a href={'face'}>
                <img src={instagram} alt="alwashim" />
              </a>
              <a href={'inst'}>
                <img src={facebook} alt="alwashim" />
              </a>
              <a href={'twi'}>
                <img src={twitter} alt="alwashim" />
              </a>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default BlogCard
