import React from 'react'
import ReactDOM from 'react-dom/client'

//App Imports
import App from './App'

//Redux Imports

//Styles Imports
import 'swiper/css/bundle'
import './index.scss'

import { store } from './store/store'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
