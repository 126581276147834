export const TRANSLATIONS_AR = {
  home: 'الرئيسية',
  branches: 'فروعنا',
  posts: 'منشورات',
  products: 'منتجاتنا',
  favorites: 'المُفضلة',
  contact: 'تواصل معنا',
  sales: 'العروض',
  clearCart: 'حذف السلة',
  Total: 'المجموع',
  Checkout: 'أطلب',
  ContinueShopping: 'أكمل التسوق',
  Remove: 'حذف',
  StartShopping: 'بدء التسوق',
  emptyCart: '😕 سلتك فارغة ',
  CATEGORIES: 'التصنيفات',
  FEATUREDPRODUCTS: 'منتجات مميزة',
  AddToCart: 'أضف الى السلة',
  SAR: 'ر.س',
  emptyFva: '✨ لا يوجد أي مٌفضلات بعد ',
  Stores: 'المتاجر',
  StoeName: 'أسم المتجر',
  TEL: 'هاتف',
  removeFromFav: 'تم ازالته من المفضلة',
  AddedToFavourit: 'تم الأضافة الى المٌفضلة',
  submit: 'ارسال',
  Wanttoknowmore: 'تود معرفة المزيد ؟',
  name: 'الاسم',
  email: 'الايميل',
  subject: 'الموضوع',
  message: 'الرسالة',
  vision: 'رؤيتنا',
  mission: 'مهمتنا',
  values: 'قيمنا',
  postsCard: '📮 لا يوجد أي منشورات حاليا',
  emptyOrders: '📦 لم تقم بوضع أي طلب حتى الآن ',
  DeleveriyMethod: 'طريقة الأستلام',
  PaymentMethod: 'طريقة الدفع',
  OrderId: 'رقم الطلب',
  Item: 'القطعة',
  ItemName: 'اسم القطعة',
  ItemPrice: 'سعر القطعة',
  Quantity: 'الكمية',
  DeleveriyFee: 'رسوم التوصيل',
  TotalQuantity: 'عدد القطع الكلي',
  BranchName: 'أسم الفرع',
  Tax: 'الضريبة',
  TotalPrice: 'اجمالي السعر',
  Delevered: 'تم التسليم',
  Rejected: 'مرفوض',
  Pending: 'قيد الأنتظار',
  Accepted: 'تم القبول',
  Prepare: 'قيد التحضير',
  OnDelevery: 'قيد التوصيل',
  Product: 'المنتج',
  Price: 'السعر',
  sentOrder: '🎉 تم أرسال طلبك',
  ThankYou: 'شكراً لك',
  sorry: 'نأسف  ',
  orderEroor: '😕 لم يتم أرسال طلبك',
  ShoppingCart: 'سلة التسوق',
  selectBranch: 'أختر الفرع',
  Delivery: 'توصيل',
  typeofDelivery: 'طريقة الأستلام',
  quantity_not_available: 'الكمية غير متوفرة',
  not_available_in_stock: 'الكمية غير متوفرة في المخزن',
  you_must_select_adds: 'يجب ان تختار من ميّزات للمنتج',
  newـarrivals: 'وصل حديثا',
  brands: 'العلامات التجارية',
}
