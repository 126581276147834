import React, { useState } from 'react';
import { authentication } from '../../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import PhoneInput from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom';
import PinField from "react-pin-field"
import swal from 'sweetalert';




import './Login.scss'
import 'react-phone-number-input/style.css';
import Loader from '../Loader';


const Login = () => {
    // const countryCode = '+962';
    // const [phoneNumber, setPhoneNumber] = useState(countryCode);
    const navigate = useNavigate();



    const [value, setValue] = useState()

    const [expandForm, setExpandForm] = useState(false);
    const [name, setName] = useState('')

    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
            }
        }, authentication);
    }

    const requestOtp = (e) => {

        e.preventDefault();
        if (value.length >= 12) {
            setExpandForm(true);
            generateRecaptcha();
            let appVerifier = window.recaptchaVerifier;

            signInWithPhoneNumber(authentication, value, appVerifier)
                .then(confirmationResult => {

                    window.confirmationResult = confirmationResult;
                }).catch(error => {
                    console.log(error);
                });
        }



    }


    // const [code, useCode] = useState();

 


    const verifyOTP = (ref) => {
        console.log(ref);
        // let otp = e.target.value;
        // setOTP(otp);

        var newOtp = value;



        if (ref?.length === 6) {

            let confirmationResult = window.confirmationResult;
            confirmationResult?.confirm(ref).then((result) => {

                const user = result.user;
                console.log(user);

            }).then(async () => {

                const response = await fetch(`https://dashboard.fouzan.digisolapps.com/api/AddUserApp?name=${name}&phone_number=${newOtp}`, {
                    method: "POST",

                }
                );
                const data = await response.json();


                 swal(`Welcome ${name}`, "InStyle", "success")
                localStorage.setItem('userToken', data.token);
                setTimeout(()=>{
                    navigate('/')
                },2000)
            })
                .catch(error => {

                    swal("Sorry", "Wrong Pin Code 😕", "error")
                    setTimeout(()=>{
                        navigate(0)
                    },2000)
 
                })

        } else {
            console.log('Please enter 6 digits');
        }
    }

    const ref = React.createRef();

 
    return (
        <section id="containerLogin">

        <Loader />
            <form className="form-login" onSubmit={requestOtp}>



                <div id="containerOne">
                    <div id="loaders"></div>
                    <h4>Log-in/Sign-up</h4>
                </div>



                {/** <input type='tel' id='phoneNumberInput' aria-describedby='email' onChange={(e) => setPhoneNumber(e.target.value)} /> */}


                <div className='infoContainer'>

                    <input
                        type='text'
                        className="userName"
                        placeholder="Name"

                        onChange={(e) => setName(e.target.value)}
                    />



                    <PhoneInput
                        placeholder="Enter phone number"
                        value={value}
                        defaultCountry="JO"
                        id="phoneNumber"
                        onChange={setValue} />


                </div>

                {expandForm === true ?

                    <div className='otpContainer'>
                        <label htmlFor='otpInput'>OTP</label>



                        {/** <input type='number' id='otpInput' value={OTP} onChange={verifyOTP} maxLength={6} /> */}
                        <div className='pinCode'>
                            <PinField length={6} ref={ref} onChange={verifyOTP} />
                        </div>

                        <div id='otpHelp'>
                            please enter the otp sent to your phone number
                        </div>
                    </div>


                    :
                    null
                }

                {
                    expandForm === false ?
                        <button type='submit' id='sign-in-button'>
                            Request OTP
                        </button>
                        :
                        null
                }
                <div id='recaptcha-container'></div>
            </form>
        </section>
    );
}

export default Login