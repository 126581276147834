import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { t } from 'i18next';

const Map = (props) => {

    let api = props.Api
    const [dataBranch, setDataBranch] = useState()

    const getBranchesData = async () => {
        const response = await axios.get(api)
        const { data } = response
        setDataBranch(data);
    }







    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    console.log(nav1);
    console.log(setNav2);


    useEffect(() => {
        getBranchesData()


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
            <div className='mapContainerBranches'>

                <h2 className='store'>{t('Stores')} ({dataBranch?.length})</h2>

                <Slider className="slidesLine mapSlide" asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                    {dataBranch?.map((data, index) => (

                        <Iframe

                            className='map-container'
                            key={index}
                            width="100%" height="450"
                            frameborder="4"
                            src={`https://www.google.com/maps/embed/v1/place?q=${data.latitude},${data.longitude}&zoom=10&key=AIzaSyBx1N_3qK4ZOBs3QL3z-KVl6P-sF8ehLVs`}
                            target="_parent"
                        >
                        </Iframe>
                    ))}


                </Slider>




               {/*
                <div id="sliderContainer">
                    <Slider
                        id="locationSlider"
                        dots={false}
                        infinite={true}
                        speed={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                        className="slidesLine"
                        asNavFor={nav1}
                        ref={(slider2) => setNav2(slider2)}


                    >
                        {dataBranch?.map((data, index) => (


                            <div className="locationContainer" key={index}>
                                <div className="locationInfo">
                                    <div>{t('StoeName')} : {data.store_name}</div>
                                    <div className='locationIc'>
                                        <MdLocationPin />
                                        <div className="storeNametext">{data.location_name}</div>
                                    </div>
                                    <div className='locationIc'>

                                        <HiPhone />
                                        <a href={`tel:${data.phone_number}`} class="Blondie">
                                            <div>{t('TEL')} : {data.phone_number}</div>
                                        </a>
                                    </div>


                                </div>
                            </div>
                        ))}





                    </Slider>
                </div>
                */}


            </div>
    )
}

export default Map