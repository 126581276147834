import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBx1N_3qK4ZOBs3QL3z-KVl6P-sF8ehLVs',
  authDomain: 'glasses-ar-b5a57.firebaseapp.com',
  projectId: 'glasses-ar-b5a57',
  storageBucket: 'glasses-ar-b5a57.appspot.com',
  messagingSenderId: '116141582093',
  appId: '1:116141582093:web:0356aec530cf1328223244',
}

const app = initializeApp(firebaseConfig)
export const authentication = getAuth(app)
