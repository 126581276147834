import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { motion, useScroll } from 'framer-motion'
import { ToastContainer } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import './App.scss'
import 'react-toastify/dist/ReactToastify.css'

import axios from 'axios'

import NavBar from './Components/NavBar/NavBar'
import Footer from './Components/Footer/Footer'
import Home from './Components/Home/Home'
import Products from './Components/Products/Products'
import Login from './Components/Login/Login'
import Profile from './Components/Profile/Profile'
import ContactUs from './Components/ContactUs/ContactUs'
import PravateRoutes from './Components/Utils/PravateRoutes'
import Branches from './Components/Branches/Branches'
import Loader from './Components/Loader'
import Cart from './Components/Products/Cart'
import MyOrders from './Components/MyOrders/MyOrders'
import UpdateProfile from './Components/Profile/UpdateProfile'
import OrderDetails from './Components/MyOrders/OrderDetails'
import FavItems from './Components/FavItems/FavItems'
import GoToTop from './Components/GoToTop'

import ItemPage from './Components/Products/ItemPage'
import './fonts/CastyaCalligraphy-WyZoY.ttf'
import PostsContainer from './Components/Blogs/PostsContainer'
import Sales from './Components/Sales'
import NewArrivals from './Components/NewArrivals'
import Brands from './Components/Products/brands'
import BrandItems from './Components/Products/brands/components/brand-items'

function App() {
  const { scrollYProgress } = useScroll()

  const { i18n } = useTranslation()

  var defLang = window.localStorage.getItem('lang')
  const [languageFrom, setLanguageFrom] = useState(defLang)
  useEffect(() => {
    document.getElementById('lang').onchange = function () {
      setLanguageFrom(this.value)
      i18n.changeLanguage(this.value)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let baseUrl = `https://dashboard.fouzan.digisolapps.com/api/${languageFrom}`

  let sliderApi = 'https://dashboard.fouzan.digisolapps.com/api/WebSlider'
  let userData = 'https://dashboard.fouzan.digisolapps.com/api/getWebUserData'
  let appLinks = `https://dashboard.fouzan.digisolapps.com/api/getApplinks`

  let getFavItemsApi = `${baseUrl}/getFavItems`
  let vissionMissionValuesApi = `${baseUrl}/principles`
  let catEgoryApi = `${baseUrl}/getCategory`
  let branchesApi = `${baseUrl}/getAllBranches`
  let myOrdersHistory = `${baseUrl}/get-my-order-web`
  let myPosts = `${baseUrl}/blogs`
  let itemDetails = `${baseUrl}/Item_Details?item_id=`

  const getLinksdata = async () => {
    const response = await axios.get(appLinks)
    const { data } = response

    setIosLink(data[0].app_store_url)
    setFaceBookLink(data[0].facebook)
    setgoogleLink(data[0].google_play_url)
    setinstagramLink(data[0].instagram_url)
    setPhonelLink(data[0].phone_number)
    setEmailLink(data[0].email)
    setWhatsAppPhoneLink(data[0].whats_app_number)
  }

  useEffect(() => {
    getLinksdata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [eamilLink, setEmailLink] = useState()

  const [phonelLink, setPhonelLink] = useState()

  const [facebookLink, setFaceBookLink] = useState()

  const [instagramLink, setinstagramLink] = useState()

  const [googleLink, setgoogleLink] = useState()

  const [iosLink, setIosLink] = useState()

  const [whatsAppPhone, setWhatsAppPhoneLink] = useState()

  return (
    <div className="App" lang={i18n.language}>
      <BrowserRouter
        style={{
          height: '100%',
        }}
      >
        <ToastContainer />
        <motion.div
          className="progress-bar"
          style={{ scaleX: scrollYProgress }}
        />
        <NavBar />
        <div className="header-margin"/>

        <React.Suspense fallback="...loading">
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  Api={sliderApi}
                  Api2={catEgoryApi}
                  Api3={vissionMissionValuesApi}
                />
              }
            />

            <Route path="/SignIn-SignUp" element={<Login />} />
            <Route element={<PravateRoutes />}>
              <Route path="/Profile" element={<Profile Api={userData} />} />
              <Route path="/UpdateProfile" element={<UpdateProfile />} />
              <Route
                path="/Favorates"
                element={<FavItems Api={getFavItemsApi} />}
              />
              <Route
                path="/cart"
                element={<Cart branchesApi={branchesApi} />}
              />
              <Route
                path="/MyOrders"
                element={<MyOrders Api={myOrdersHistory} />}
              />
              <Route
                path="/OrderDetails/:id"
                element={<OrderDetails Api={myOrdersHistory} />}
              />
            </Route>

            <Route path="/Branches" element={<Branches Api={branchesApi} />} />
            <Route path="/Posts" element={<PostsContainer api={myPosts} />} />
            <Route path="/Products" element={<Products api={catEgoryApi} />} />
            <Route
              path="/Contact"
              element={
                <ContactUs
                  email={eamilLink}
                  phone={phonelLink}
                  google={googleLink}
                  ios={iosLink}
                />
              }
            />
            <Route path="/Loader" element={<Loader />} />

            <Route
              path="/ItemPage/:id"
              element={<ItemPage Api={itemDetails} />}
            />

            <Route path="/Sales" element={<Sales />} />
            <Route path="/NewArrivals" element={<NewArrivals />} />
            <Route path="/Brands" element={<Brands />} />
            <Route path="/BrandItems/:id" element={<BrandItems />} />
          </Routes>
        </React.Suspense>
        <Footer
          email={eamilLink}
          phone={phonelLink}
          google={googleLink}
          ios={iosLink}
          face={facebookLink}
          insta={instagramLink}
          whats={whatsAppPhone}
        />
        <center className="poweredBy">
          2023 © Powerd by
          <a href="https://digisolfze.com" target="_blank" rel="noreferrer">
            {' '}
            <span id="digiCompany">
              {' '}
              <u>DigiSol</u>😎
            </span>
          </a>
        </center>
        <GoToTop />
      </BrowserRouter>
    </div>
  )
}

export default App
