/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import './Products.scss'
import ItemsProducts from './ItemsProducts'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import glasses from '../../Images/glasses.jpg'
import Loader from '../Loader'
import style from './items.module.scss'
import { productsFetch } from '../../slices/productsSlice'

const NewArrivals = (props) => {
  var lang = window.localStorage.getItem('lang') ?? 'ar'
  let catApi = `https://dashboard.fouzan.digisolapps.com/api/sale?lang=${lang}`

  const [category, setCategory] = useState()
  const [activeCategoryId, setActiveCategoryId] = useState(1)

  const getCategory = async () => {
    const response = await axios.get(catApi)
    const data = await response.data.data
    setCategory(data)
  }

  useEffect(() => {
    getCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const categoryClick = (id) => {
    if (activeCategoryId === id) {
      return
    } else {
      setActiveCategoryId(id)
    }
  }

  const dispatch = useDispatch()

  const productsData = useSelector((state) => state.products)
  useEffect(() => {
    dispatch(productsFetch(activeCategoryId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategoryId])

  const [products, setProducts] = useState()
  useEffect(() => {
    var lang = window.localStorage.getItem('lang') ?? 'ar'
    const api = `https://dashboard.fouzan.digisolapps.com/api/new_arrival?lang=${lang}`
    axios.get(api).then(function (res) {
      console.log('products are ', res.data)
      return setProducts(res.data.data)
    })
  }, [])

  return (
    <div className="productsContainerId">
      <Loader />

      <div className={style.productsContainer}>
        {products?.map(function (product, index) {
          return (
            <ItemsProducts
              key={index}
              id={product.id}
              product={product}
              productName={product.item_name}
              disCountPrice={product.Discount_price}
              itemImage={product.item_image}
              fav={product.fav_item}
              item_price={product.item_price}
            />
          )
        })}
      </div>
    </div>
  )
}

export default NewArrivals
