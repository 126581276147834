import React from 'react'
import loader from '../Images/loader.gif'
import './Loader.scss'
import { useState } from 'react'
import { useEffect } from 'react'

const Loader = () => {
  const [load, setLoad] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoad(false)
    }, 1500)
  }, [])

  return (
    load && (
      <div id="loader">
        <img src={loader} alt="Loading..." />
      </div>
    )
  )
}

export default Loader
