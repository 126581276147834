import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

var lang = window.localStorage.getItem('lang');
var token = window.localStorage.getItem('userToken');

export const popularFetch = createAsyncThunk(
  'popular/popularFetch',

  async () => {
    try {
      const response = await axios.get(
        `https://dashboard.fouzan.digisolapps.com/api/${lang}/most_popular_item`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  items: [],
  status: null,
};

const cartSlice = createSlice({
  name: 'popular',
  initialState,
  reducers: {},
  extraReducers: {
    [popularFetch.fulfilled]: (state, actions) => {
      state.items = actions.payload;
      state.status = 'success';
    },
    [popularFetch.pending]: (state, actions) => {
      state.status = 'pending';
    },

    [popularFetch.rejected]: (state) => {
      state.status = 'rejected';
    },
  },
});

export default cartSlice.reducer;
