import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

import './Profile.scss';
import Loader from '../Loader';


const Profile = (props) => {



    const [dUserr, setDUserr] = useState()





    let token = window.localStorage.getItem('userToken')









    const [messageAfter, setMessageAfter] = useState("");
    let navigate = useNavigate();


    const UpdateInfo = async (e) => {
        e.preventDefault();


        const req = {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify({


                firstName: dUserr,

            })
        }

        try {
            let sendReq = await fetch("https://dashboard.fouzan.digisolapps.com/api/UserProfile", req)
            await sendReq.json()


            if (sendReq.status < 300 || sendReq.status > 200) {

                setMessageAfter("Profile Updateed");

                navigate("/Profile");

            }


            else {
                setMessageAfter("Message Failed to be sent");
            }

        } catch (err) {
            setMessageAfter("Message Failed to be sent");

        }


    }

    const returnBack = () => {

        navigate("/Profile");
    }
    return (
        <div id='userInfo' className='secondContainer'>
            <Loader />


            <BiArrowBack fontSize={'34px'} className="arrowBack" onClick={returnBack} />

            <div id='insideUserInfo'>


                <form onSubmit={UpdateInfo}>

                    <input
                        className="form-control second"
                        type="text"
                        placeholder="Name"
                        value={dUserr}
                        onChange={(e) => setDUserr(e.target.value)}
                    />




                    <button className="btn-updateTwo" type='submit'>
                        Update
                    </button>
                    <div className="message">{messageAfter ? <p>{messageAfter}</p> : null}</div>


                </form>

            </div>

        </div>
    )
}

export default Profile