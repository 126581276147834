import React from 'react'
import PostsCard from './PostsCard'
import mainImage from '../../Images/book.png'
const PostsContainer = ({ api }) => {
  return (
    <div id="Blogs-container">
      <div id="leftSideBlog">
        <PostsCard api={api} />
      </div>
      <div id="rightSideBlog">
        <img src={mainImage} alt="post" />
      </div>
    </div>
  )
}

export default PostsContainer
