import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  clearCart,
  decreaseCart,
  getTotals,
  removeFromCart,

} from "../../slices/cartSlice";
import { getAllCart } from '../../slices/cartSlice'
import { Link } from "react-router-dom";
import GoogleMap from "./GoogleMap"
import './cart.scss'
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import Loader from "../Loader";


const Cart = (props) => {


  const branches = props.branchesApi
  const { t } = useTranslation()

  const [deleviry, setDeleviry] = useState()
  const [branchIdSelected, seBranchId] = useState(14)
  const [paymentMethod, setPaymentMethod] = useState()






  var token = localStorage.getItem("userToken");




  var delType = localStorage.getItem("deleveriyType")


  if (delType === undefined || null) {
    delType = localStorage.setItem("deleveriyType", "Pickup")

  }

  if (delType === "Deleviry") {
      localStorage.getItem("latitude")
     localStorage.getItem("longitude")
  } else {
     localStorage.setItem("latitude", null)
  localStorage.setItem("longitude", null)
  }


  useEffect(() => {
    localStorage.removeItem("deleveriyType")
    localStorage.setItem("deleveriyType", "Pickup")

  }, [])

  const createOrder = async (e) => {
    e.preventDefault();


    const req = {
      method: "POST",

      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + token,

      },

      body: JSON.stringify({
        Total_Amount: cart.cartTotalAmount,
        DropOffAddressLat:  localStorage.getItem("latitude"),
        DropOffAddressLong: localStorage.getItem("longitude"),
        pickupType: delType,
        paymentMethod: "Cash",
        branch: branchIdSelected,

        cart: cartItems,
      })
    }


    try {
      let sendReq = await fetch(`https://dashboard.fouzan.digisolapps.com/api/webOrders`, req)
      const responseCode = await sendReq.json()

console.log(responseCode.status_number);
    

     if (responseCode.status_number === "500") {
        swal(t('sorry'), t("quantity_not_available"), "error");


      }
      else if (responseCode.status_number === "501") {
        swal(t('sorry'), t("not_available_in_stock"), "error");


      }

      else {
        
        swal(t('ThankYou'), t('sentOrder'), "success");
        dispatch(clearCart());
      }

    } catch (err) {
      console.log(err);
       swal(t('sorry'), t("you_must_select_adds"), "error");

    }


  }



  const [branchesData, setBranchesData] = useState()

  const getBranchesDats = async () => {
    const response = await fetch(branches)
    const data = await response.json()
    setBranchesData(data);
  }





  useEffect(() => {
    getBranchesDats()


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])


  const cart = useSelector(getAllCart);
  console.log(cart);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };
  const handleClearCart = () => {

    dispatch(clearCart());
  };

  const { cartItems } = useSelector((state) => state.cart);

  const handlePayMent = (e) => {
    let r = window.localStorage.setItem('paymentMethod', e.target.value);
    setPaymentMethod(e.target.value);
    window.localStorage.setItem(r, e.target.value)



  };

  const deleveriyType = (e) => {
    console.log(e.target.value);
    setDeleviry(e.target.value);
     window.localStorage.setItem("deleveriyType", e.target.value)

  }
  const branchId = (e) => {
     seBranchId(e.target.value)

  }

 
  return (
    <div className="cart-container">
    <Loader />
      <h2>{t("ShoppingCart")}</h2>
      {cart.cartItems.length === 0 ? (
        <div className="cart-empty">
          <p>{t('emptyCart')}</p>
          <div className="start-shopping">
            <Link to="/Products">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              <span className="emptyText">{t('StartShopping')}</span>
            </Link>
          </div>
        </div>
      ) : (
        <div className="newCon">
          <div>
            <div className="titles">
              <h3 className="product-title">{t('Product')}</h3>
              <h3 className="price">{t('Price')}</h3>
              <h3 className="quantity">{t('Quantity')}</h3>
              <h3 className="total">{t('Total')}</h3>
            </div>
            <div className="cart-items">
              {cart.cartItems &&
                cart.cartItems.map((cartItem,index) => (
                  <div className="cart-item" key={index}>
                    <div className="cart-product">
                      <img src={cartItem.item_image} alt={cartItem.item_name} />
                      <div className="anotherCon">
                        <h3>{cartItem.item_name}</h3>
                        <button onClick={() => handleRemoveFromCart(cartItem)}>
                          {t('Remove')}
                        </button>
                      </div>
                    </div>
                    {cartItem.Discount_price === "No Discount" ?
                      (<div className="cart-product-price"><h4>{parseFloat(cartItem.item_price) + cartItem.addsTotal} {t('SAR')}</h4></div>) : (<div><del>{cartItem.item_price}</del><h4>{cartItem.Discount_price} SAR</h4> </div>)}
                    <div className="cart-product-quantity">
                      <button onClick={() => handleDecreaseCart(cartItem)}>
                        -
                      </button>
                      <div className="count">{cartItem.cartQuantity}</div>

                      <button onClick={() => handleAddToCart(cartItem)} disabled={cartItem.cartQuantity === cartItem.main_quantity}>+</button>
                    </div>

                    {cartItem.Discount_price === "No Discount" ? (
                      <div className="cart-product-total-price">
                         {((parseFloat(cartItem.item_price) + cartItem.addsTotal) * cartItem.cartQuantity).toFixed(2)} {t('SAR')}
                      </div>
                    ) : (<div className="cart-product-total-price">

                  {((parseFloat(cartItem.Discount_price) + cartItem.addsTotal) * cartItem.cartQuantity).toFixed(2)} {t('SAR')}

                     </div>)}



                  </div>

                ))}
            </div>
          </div>
          <div className="cart-summary">

            <div className="cart-checkout">


              <div className="DeliveryMethod">
                <h4>{t('typeofDelivery')}</h4>
                <select id="DeliverySelect" onChange={deleveriyType}>
                  <option value="Pickup" defaultValue>PickUp</option>
                  <option value="Deleviry">{t('Delivery')}</option>

                </select>

                {deleviry === "Deleviry" ? (<GoogleMap />) : (<div className="branch-class">
                  <h4>{t('selectBranch')}</h4>
                  <select value={branchIdSelected} onChange={branchId}>
                    {branchesData?.map((data) => (

                      <option value={data.id} key={data.id}>{data.location_name}</option>
                    ))}

                  </select>
                </div>)}

              </div>
              <div className="paymentTypeButtons" value={paymentMethod}>
                <h4>Payment Method</h4>
                {/*<input type="radio" name="pay" value="online" onClick={handlePayMent} disabled /> Online */}
                <input type="radio" name="pay" value="Cash" onClick={handlePayMent} defaultChecked /> Cash
              </div>



              <p className="taxes">Taxes 15%</p>


              <br />
              <div className="subtotal">
                <span>{t('Total')}</span>
                <span className="amount">{cart.cartTotalAmount.toFixed(3)} SAR</span>
              </div>

              <button onClick={createOrder}>{t('Checkout')}</button>
              <div className="clear-continue">
                <button className="clear-btn" onClick={() => handleClearCart()}>
                  {t('clearCart')}
                </button>
                <div className="continue-shopping">
                  <Link to="/Products">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrow-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                      />
                    </svg>

                    <span>{t('ContinueShopping')}</span>




                  </Link>

                </div>
              </div>
            </div>




          </div>
        </div>
      )}



    </div>

  );
};

export default Cart;
