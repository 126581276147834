/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import logo from '../../Images/footerLogo.png'

import { useTranslation } from 'react-i18next'
import { FaClipboardList } from 'react-icons/fa'
import { GiWorld } from 'react-icons/gi'
import { GoPerson } from 'react-icons/go'
import { MdShoppingCart } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import swingLogo from '../../Images/swingLogo.png'
import '../../translations/i18n'
import './NavBar.scss'

import footerLogo from '../../Images/footerLogo.png'
import axios from 'axios'

const NavBar = ({ ...rest }) => {
  const navRef = useRef()
  const routePath = useLocation().pathname
  const searchpath = useLocation().search
  const navigate = useNavigate()

  const [hoveredCategory, setHoveredCategory] = useState(null)
  const [subCats, setSubCats] = useState()
  useEffect(() => {
    if (hoveredCategory === null) return

    const endpoint = `https://dashboard.fouzan.digisolapps.com/api/category/subcategory?lang=en&category_id=${hoveredCategory.id}`
    axios
      .get(endpoint)
      .then((res) => res.data.data)
      .then((data) => setSubCats(data))
  }, [hoveredCategory])

  const { cartTotalQuantity } = useSelector((state) => state.cart)

  const color = {
    color: 'black',
  }

  const colorTwo = {
    color: '#848484',
  }

  const handleLang = (e) => {
    window.localStorage.setItem('lang', e.target.value)
    window.location.reload()
  }

  var defaultLang = window.localStorage.getItem('lang')
  const { t } = useTranslation()
  const { i18n } = useTranslation()

  const dir = {
    direction: `${i18n.dir()}`,
  }

  useEffect(() => {}, [handleLang])

  const lang = i18n.resolvedLanguage ?? 'en'
  let categoriesApi = `https://dashboard.fouzan.digisolapps.com/api/${lang}/getCategory`

  const [categories, setCategories] = useState()
  useEffect(() => {
    fetch(categoriesApi)
      .then((res) => res.json())
      .then((data) => setCategories(data))
  }, [])

  return (
    <>
      <div className="navContainer" {...rest}>
        <div className="nav-bar-top">
          <img className="logo desktop" src={logo} alt="logo" height={42} />

          <NavLink to="/Profile" className="nav1">
            {' '}
            <GoPerson fontSize={'26px'} className="nav1" color="white" />{' '}
          </NavLink>
          <NavLink to="/MyOrders" className="nav1">
            {' '}
            <FaClipboardList fontSize={'26px'} className="nav1" color="white" />
          </NavLink>
          <NavLink to="/Cart" className="nav-bag">
            <span className="bag-quantity">{cartTotalQuantity}</span>
            <MdShoppingCart fontSize={'26px'} color="white" />
          </NavLink>
          <GiWorld fontSize={'26px'} color={'white'} />
          <li id="liLang">
            <select
              id="lang"
              onChange={handleLang}
              defaultValue={defaultLang}
              style={{
                color: 'black',
                backgroundColor: 'white',
                padding: '4px',
              }}
            >
              <option value="en">En</option>
              <option value="ar">العربية</option>
            </select>
          </li>
        </div>

        <nav style={dir}>
          <img className="logo mobile" src={logo} alt="logo" height={42} />

          <NavLink to="/">
            <div className="swing">
              <img src={swingLogo} alt="Instyle" />
            </div>
          </NavLink>
          <nav ref={navRef}>
            <NavLink
              onMouseEnter={() => setHoveredCategory(null)}
              to="/"
              id="homeNav"
              style={routePath === '/' ? color : colorTwo}
            >
              {t('home')}
            </NavLink>

            <NavLink
              onMouseEnter={() => setHoveredCategory(null)}
              to="/NewArrivals"
              id="homeNav"
              style={routePath === '/NewArrivals' ? color : colorTwo}
            >
              {t('newـarrivals')}
            </NavLink>

            {categories?.map(function (cat, index) {
              return (
                <a
                  id="homeNav"
                  onClick={() => {
                    navigate(`/Products/?category=${cat.id}`)
                  }}
                  onMouseEnter={() => setHoveredCategory(cat)}
                  style={
                    routePath === `/Products/` &&
                    searchpath === `?category=${cat.id}`
                      ? color
                      : colorTwo
                  }
                >
                  {cat.category_name}
                </a>
              )
            })}

            {/* <NavLink
              onMouseEnter={() => setShowSubCatNavBar(null)}
              to="/Products"
              id="homeNav"
              style={routePath === '/Products' ? color : colorTwo}
            >
              {t('products')}
            </NavLink> */}

            {/* <NavLink
              onMouseEnter={() => setShowSubCatNavBar(null)}
              to="/Favorates"
              id="homeNav"
              style={routePath === '/Favorates' ? color : colorTwo}
            >
              {t('favorites')}
            </NavLink>

            <NavLink
              onMouseEnter={() => setShowSubCatNavBar(null)}
              to="/Posts"
              id="homeNav"
              onClick={showNav}
              style={routePath === '/Posts' ? color : colorTwo}
            >
              {t('posts')}
            </NavLink> */}

            {/* <NavLink
              onMouseEnter={() => setShowSubCatNavBar(null)}
              to="/Branches"
              id="homeNav"
              style={routePath === '/Branches' ? color : colorTwo}
            >
              {t('branches')}
            </NavLink> */}

            <NavLink
              onMouseEnter={() => setHoveredCategory(null)}
              to="/Brands"
              id="homeNav"
              style={routePath === '/Brands' ? color : colorTwo}
            >
              {t('brands')}
            </NavLink>

            <NavLink
              onMouseEnter={() => setHoveredCategory(null)}
              to="/sales"
              id="homeNav"
              style={routePath === '/sales' ? color : colorTwo}
            >
              {t('sales')}
            </NavLink>

            <NavLink
              onMouseEnter={() => setHoveredCategory(null)}
              to="/Contact"
              id="homeNav"
              style={routePath === '/Contact' ? color : colorTwo}
            >
              {t('contact')}
            </NavLink>
          </nav>
        </nav>
        {hoveredCategory && (subCats ?? []).length && (
          <nav
            className="sub-cat-navbar"
            onMouseLeave={() => setHoveredCategory(null)}
          >
            {subCats?.map((s, i) => (
              <NavLink
                to={`/Products/?category=${hoveredCategory.id}&subCategory=${s.id}`}
                id="homeNav"
                style={routePath === '/Products' ? color : colorTwo}
              >
                {lang === 'en' ? s.category_name_en : s.category_name_ar}
              </NavLink>
            ))}
          </nav>
        )}
        <img src={footerLogo} alt="" className="phoneLogo" />
      </div>
    </>
  )
}

export default React.memo(NavBar)
