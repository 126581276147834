/* eslint-disable */

import React, { useMemo, useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { productsFetch } from '../../slices/productsSlice'
import { addToCart, getTotals } from '../../slices/cartSlice'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllCart } from '../../slices/cartSlice'
import './ProductPage.scss'
import uuid from 'react-uuid'

import {
  Box,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  ImageListItem,
} from '@mui/material'
import Loader from '../Loader'
import { Pagination, Autoplay } from 'swiper'
import { SwiperSlide, Swiper } from 'swiper/react'

const ItemPage = ({ Api }) => {
  const [product, setProduct] = useState()

  const [skills, setSkills] = useState([])

  // const [selectedRadio, setSelectedRadio] = useState();

  const handleRadioChange = (event) => {
    // setSelectedRadio(event.target.value);
    setSkills([...skills, event.target.value])
  }

  const productsData = useSelector((state) => state.products)
  const { t } = useTranslation()
  const { id } = useParams()

  const dispatch = useDispatch()

  let navigate = useNavigate()

  useEffect(() => {}, [dispatch])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [token, setToken] = useState()

  useMemo(() => {
    setToken(window.localStorage.getItem('userToken'))
  }, [])
  useMemo(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsData])

  useMemo(() => {
    dispatch(productsFetch())
  }, [dispatch])

  const cart = useSelector(getAllCart)

  useEffect(() => {
    dispatch(getTotals())
  }, [cart, dispatch])

  const getItemData = async () => {
    const data = await fetch(Api + id)
    const fetchedData = await data.json()

    setProduct(fetchedData[0])
    setProductImages(fetchedData)
  }

  useEffect(() => {
    getItemData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [productsImages, setProductImages] = useState([])
  const images = productsImages?.flatMap((product) => {
    let i = product?.galary?.map((image) => image?.image)
    return i === null ? [product?.item_image, ...i] : [];
  })

  const [pricesList, setPricesList] = useState([])
  const [disa, setDisa] = useState()

  const handelSkillsChange = (e) => {
    const index = skills.indexOf(e.target.value)

    if (index === -1) {
      setSkills([...skills, e.target.value])
      setDisa({
        ...disa,
        [e.currentTarget.dataset.group]: e.target.value,
      })

      setPricesList({
        ...pricesList,
        [e.target.value]: e.currentTarget.dataset.price,
      })
    } else {
      setSkills([...skills.filter((skill) => skill !== e.target.value)])
      delete pricesList[e.target.value]
      delete disa[e.currentTarget.dataset.group]
    }
  }

  const values = Object.values(pricesList)

  parseInt(values)
  const sum = values.reduce((accumulator, value) => {
    return accumulator + parseFloat(value)
  }, 0)

  const handleAddToCart = (product) => {
    if (token != null || undefined) {
      dispatch(
        addToCart(
          product,
          (product.item_id = parseInt(id)),
          (product.adds = skills),
          (product.orderId = uuid()),
          (product.addsTotal = sum)
        )
      )
    } else {
      navigate('/SignIn-SignUp')
    }
  }

  const hasDiscount =
    product?.Discount_price &&
    product?.Discount_price !== 'No Discount' &&
    product?.Discount_price !== product?.item_price

  return (
    <div>
      <Loader />

      <main className="cardPage" key={id}>
        {images[0] !== undefined ? (
          <Swiper
            autoplay={{ delay: 3000 }}
            slidesPerView={1}
            spaceBetween={20}
            loop={true}
            loopFillGroupWithBlank={false}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiperTwo slider"
          >
            {images?.map((image, index) => (
              <SwiperSlide className="item-slider" key={index}>
                <img src={image} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <img
            className="card__img"
            src={product?.item_image}
            aria-hidden
            alt={'fouzan'}
          />
        )}

        <div className="card__content">
          <div>
            <h1 className="item-name">{product?.item_name}</h1>
            <p className="item-desc">{product?.item_description}</p>
          </div>

          <div>
            {product?.addonscategory != null ? (
              <Box>
                <FormControl>
                  {product?.addonscategory.map((data) => (
                    <div>
                      <FormLabel>{data.category_add_ons_name}</FormLabel>
                      <FormGroup row>
                        {data.max > 1 ? (
                          <>
                            {data?.options.map((d, inId) => (
                              <FormControlLabel
                                key={inId}
                                label={`${d.optionName} (${parseFloat(
                                  d.price
                                )} SAR)`}
                                control={
                                  <input
                                    className="checkBoxInput"
                                    type="checkbox"
                                    value={String(d?.id)}
                                    data-price={d?.price}
                                    data-group={
                                      data.category_add_ons_name + inId
                                    }
                                    onChange={handelSkillsChange}
                                    size="small"
                                    color="success"
                                    name={d.optionName}
                                    disabled={
                                      !skills?.includes(String(d?.id)) &&
                                      skills.length === 100
                                    }
                                    checked={skills?.includes(String(d?.id))}
                                  />
                                }
                              />
                            ))}
                          </>
                        ) : (
                          <>
                            {data?.options.map((d, inId) => (
                              <label key={d.id}>
                                <input
                                  type="radio"
                                  name={data.category_add_ons_name}
                                  value={d.id}
                                  onChange={handleRadioChange}
                                />
                                {d.optionName}
                              </label>
                            ))}
                          </>
                        )}
                      </FormGroup>
                    </div>
                  ))}
                </FormControl>
              </Box>
            ) : (
              ''
            )}

            {!hasDiscount ? (
              <span className="price--discount">
                {t('SAR')} {parseFloat(product?.item_price) + sum}
              </span>
            ) : (
              <div className="item-price">
                <span className="price--discount">
                  {t('SAR')} {product?.Discount_price}
                </span>
                {hasDiscount && (
                  <span className="price--original sr-only">
                    {t('SAR')} {product?.item_price}
                  </span>
                )}
              </div>
            )}
            <button
              className="cta-btn"
              onClick={() => handleAddToCart(product)}
            >
              <img
                className="cta-icon"
                src="https://kellychi22.github.io/frontend-mentor-solutions/01-product-preview-card-component/images/icon-cart.svg"
                alt="cart icon"
              />
              <span>{t('AddToCart')}</span>
            </button>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ItemPage
