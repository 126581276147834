import { configureStore } from '@reduxjs/toolkit'
import cartReducer, { getTotals } from '../slices/cartSlice'
import productsReducer from '../slices/productsSlice'
import favReducer from '../slices/favSlice'
import popularReducer from '../slices/mostPopularSlice'

export const store = configureStore({
  reducer: {
    products: productsReducer,
    cart: cartReducer,
    fav: favReducer,
    popluar: popularReducer,
  },
})

store.dispatch(getTotals())
