/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import BrandCard from './components/brand-card'
import s from './style.module.scss'
import { useState, useEffect } from 'react'
import axios from 'axios'

export default function Brands() {
  const [brands, setBrands] = useState()

  const lang = window.localStorage.getItem('lang') ?? 'ar'
  const api = `https://dashboard.fouzan.digisolapps.com/api/brand?lang=${lang}`

  useEffect(() => {
    axios
      .get(api)
      .then((res) => res.data.data)
      .then((data) => setBrands(data))
  }, [])

  return (
    <div className={s.container}>
      {brands?.map((b) => (
        <BrandCard
          name={lang === 'en' ? b.brand_name_en : b.brand_name_ar}
          image={b.image_url}
          id={b.id}
          key={b.id}
        />
      ))}
    </div>
  )
}
