import React from 'react';
import './Footer.scss';
import footerLogo from '../../Images/footerLogo.png';
import { NavLink } from 'react-router-dom';
import { IoLogoWhatsapp } from 'react-icons/io';
import { AiOutlineMail, AiFillInstagram } from 'react-icons/ai';
import { ImFacebook2 } from 'react-icons/im';
import { useTranslation } from 'react-i18next';


const Footer = (props) => {
    const { t } = useTranslation()
    const { i18n } = useTranslation();



    const dir = {
        direction: `${i18n.dir()}`
    }

    return (
        <footer style={dir}>


            <NavLink to="/"> <img src={footerLogo} alt="Fauzan glasses" /></NavLink>



            <div className='pagesColumnFooter'>
                <div className='firstColPages-footer'>
                    <NavLink to="/">{t('home')}</NavLink>
                    <NavLink to="/Products">{t('products')}</NavLink>
                    <NavLink to="/FAVORATES">{t('favorites')}</NavLink>
                </div>


                <div className='firstColPages-footer'>

                    <NavLink to="/">{t('posts')}</NavLink>
                    <NavLink to="/BRANCHES">{t('branches')}</NavLink>
                    <NavLink to="/Contact">{t('contact')}</NavLink>
                </div>
            </div>
            <div className='socail-media-col'>
                <a href={`https://wa.me/${props.whats}?text=Hello`} target='_blank' rel='noreferrer'>   <IoLogoWhatsapp /> </a>
                <a href={props.face} target='_blank' rel='noreferrer'>   <ImFacebook2 /></a>
                <a href={props.insta} target='_blank' rel='noreferrer'>   <AiFillInstagram /></a>
                <a href={`mailto:${props.email}`}>   <AiOutlineMail /></a>
            </div>



        </footer>
    )
}

export default React.memo(Footer)