import React, { useState, useEffect } from 'react';
import './Home.scss';
import LabTabs from './MuiTabs.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { useDispatch, useSelector } from 'react-redux';
import { popularFetch } from '../../slices/mostPopularSlice';
import { productsFetch } from '../../slices/productsSlice';
import { useTranslation } from 'react-i18next';
import { Navigation } from 'swiper';
import Loader from '../Loader';

import style from '../Products/items.module.scss';
import FeaturedProducts from './FeaturedProducts';

const Home = (props) => {
  const { i18n } = useTranslation();
  const lang = i18n.resolvedLanguage ?? 'en';

  SwiperCore.use([Autoplay]);
  let navigate = useNavigate();

  let sliderData = props.Api;

  let vimiva = props.Api3;

  const [swip, setSwip] = useState([]);

  useEffect(() => {
    const sliderApi = async () => {
      const response = await fetch(sliderData);
      const data = await response.json();

      setSwip(data);
    };

    sliderApi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let api2 = props.Api2;

  const [catData, setCatData] = useState([]);

  const getCatData = async () => {
    const response = await axios.get(api2);
    const { data } = await response;

    setCatData(data);
  };

  useEffect(() => {
    getCatData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();
  const popularData = useSelector((state) => state.popluar);

  useEffect(() => {
    dispatch(popularFetch());
  }, [dispatch]);

  useEffect(() => {}, [popularData]);

  useEffect(() => {
    dispatch(productsFetch());
  }, [dispatch]);

  const { t } = useTranslation();

  const [categories, setCategories] = useState(null);

  let catApi = `https://dashboard.fouzan.digisolapps.com/api/${lang}/getCategory`;
  const getCategory = async () => {
    const response = await axios.get(catApi);
    const data = await response.data;
    console.log(data);
    setCategories(data);
  };

  useEffect(() => {
    getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [coverImages, setCoverImages] = useState(null);
  useEffect(() => {
    axios
      .get(`https://dashboard.fouzan.digisolapps.com/api/${lang}/getBanner`)
      .then((res) => res.data)
      .then((payload) => setCoverImages(payload.data));
  }, [lang]);

  return (
    <section id='homeSection'>
      <Loader />
      {!!categories && categories.length !== 0 && (
        <div className='cat-container desktop'>
          {Array.isArray(coverImages) &&
            coverImages?.map((img, i) => {
              return (
                <div className='cat-image-container' key={i}>
                  <img className='cat-image' src={img.img} alt={'cover'} />
                </div>
              );
            })}
        </div>
      )}

      <Swiper
        className='mobile'
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        loop={true}
        modules={[Autoplay]}
        height={'30vh'}
        style={{
          marginTop: '60px',
        }}
      >
        {Array.isArray(coverImages) &&
          coverImages?.map((img, i) => {
            return (
              <SwiperSlide key={i}>
                  <img src={img.img} alt={'cover'} style={{
                  objectFit: 'cover',
                  height: '300px',
                  }} />
              </SwiperSlide>
            );
          })}
      </Swiper>

      <LabTabs api={vimiva} />
      <h1 id='categoriesTitle'>{t('CATEGORIES')}</h1>
      <Swiper
        slidesPerView={2}
        spaceBetween={20}
        loop={true}
        loopFillGroupWithBlank={false}
        navigation={true}
        modules={[Pagination, Navigation]}
        className='mySwiperTwo'
      >
        {catData?.map(function (data, index) {
          return (
            <SwiperSlide className='catigoresSlider' key={index}>
              <img src={data.category_image_url} alt='' />
              <button
                onClick={() => {
                  navigate(`/Products/?category=${data.id}`);
                }}
                value={data.id}
              >
                {data.category_name}
              </button>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        autoplay={{ delay: 3000 }}
        modules={[Pagination]}
        className='mySwiper'
      >
        {swip?.map((data, index) => (
          <SwiperSlide key={index}>
            <img
              className='bigSliderImg'
              src={data.Silder_image}
              alt='Abdulwahed'
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <h1 id='featuredTitle'>{t('FEATUREDPRODUCTS')}</h1>
      <div className={style.productsContainer}>
        {popularData.items?.map((product) => (
          <FeaturedProducts product={product} />
        ))}
      </div>
    </section>
  );
};

export default React.memo(Home);
