/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import s from '../style.module.scss'
import ItemsProducts from '../../ItemsProducts'

export default function BrandItems() {
  const params = useParams()

  const lang = window.localStorage.getItem('lang') ?? 'ar'
  const api = `https://dashboard.fouzan.digisolapps.com/api/brand/items?lang=${lang}&brand_id=${params.id}`

  const [items, setItems] = useState()
  useEffect(() => {
    axios
      .get(api)
      .then((res) => res.data.data)
      .then((data) => setItems(data))
  }, [])

  return (
    <div className="productsContainerId">
      <div className={s.productsContainer}>
        {items?.map(function (item, index) {
          return (
            <ItemsProducts
              key={index}
              id={item.id}
              product={item}
              productName={item.item_name}
              disCountPrice={item.Discount_price}
              itemImage={item.item_image}
              fav={item.fav_item}
              item_price={item.item_price}
            />
          )
        })}
      </div>
    </div>
  )
}
