import React, { useState } from 'react';
import './ContactUs.scss';
import imgw from '../../Images/contactus.png'
import phoneIcon from '../../Images/phone.png';
import emailIcon from '../../Images/message.png';
import locationIcon from '../../Images/loaction.png';
import google from '../../Images/downloadGoogle.png'
import apple from '../../Images/downloadApple.png'
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';


const ContactUs = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [messageAfter, setMessageAfter] = useState("");


    const handelForm = async (e) => {
        e.preventDefault();


        const req = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user_name: name,
                user_email: email,
                user_subject: subject,
                user_note: message
            })
        }

        try {
            let sendReq = await fetch("https://dashboard.fouzan.digisolapps.com/api/contact_us", req)
            await sendReq.json()


            if (sendReq.status < 300 || sendReq.status > 200) {
                setName("");
                setEmail("");
                setMessage("")
                setSubject("");
                setMessageAfter("Message sent");


            }


            else {
                setMessageAfter("Message Failed to be sent");
            }

        } catch (err) {
            setMessageAfter("Message Failed to be sent");

        }


    }
    const { i18n } = useTranslation();



    const dir = {
        direction: `${i18n.dir()}`
    }

    return (
        <div id='contactUsPage' style={dir}>
        <Loader />


            <h1 id='contactUsHead'>{t('contact')}</h1>




            <div id='formContainer'>

                <img src={imgw} alt="Fauzan glasses" />


                <form id='contactForm' onSubmit={handelForm}>
                    <h3>{t('Wanttoknowmore')}</h3>
                    <input
                        type="text"
                        placeholder={t('name')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required


                    />
                    <input
                        type="email"
                        placeholder={t('email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder={t('subject')}
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder={t('message')}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    />


                    <button type="submit" className='subMitBtn'>{t('submit')}</button>
                    <div className="message">{messageAfter ? <p>{messageAfter}</p> : null}</div>

                </form>

            </div>

            <div className="infoCon">

                <div className='lastLineContact'>
                    <a href="#/" target="_blank" rel="noopener"><img src={locationIcon} alt="Fauzan glasses" /></a>
                    <a href={`mailto:${props.email}`}><img src={emailIcon} alt="Fauzan glasses" /></a>
                    <a href={`tel:${props.phone}`}><img src={phoneIcon} alt="Fauzan glasses" /></a>


                </div>

                <div className="downloadContainer">
                    <a href={props.ios} target="_blank" rel="noreferrer"><img src={apple} alt="Fauzan glasses" /></a>
                    <a href={props.google} target="_blank" rel="noreferrer"><img src={google} alt="Fauzan glasses" /></a>

                </div>
            </div>
        </div>

    )
}

export default ContactUs