import React from 'react';
import style from '../Products/items.module.scss';
import { CiHeart } from 'react-icons/ci';
import { useState } from 'react';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../slices/cartSlice';
import uuid from 'react-uuid';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import salePrice from '../../Images/saleImage.png';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const FeaturedProducts = ({ product }) => {
  const [token, setToken] = useState();
  const [pricesList, setPricesList] = useState([]);
  const [skills, setSkills] = useState([]);
  console.log(setPricesList, setSkills);
  const [isFav, setFav] = useState(product.fav_item);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useMemo(() => {
    setToken(window.localStorage.getItem('userToken'));
  }, []);

  const handleAddToCart = (product) => {
    if (token != null || undefined) {
      const totalPrice = Object.values(pricesList).reduce(
        (accumulator, value) => accumulator + parseFloat(value),
        0
      );
      dispatch(
        addToCart({
          ...product,
          item_id: parseInt(product.item_id),
          adds: skills,
          orderId: uuid(),
          addsTotal: totalPrice,
        })
      );
    } else {
      navigate('/SignIn-SignUp');
    }
  };

  const handleFavoriteClick = async (favId, isFav) => {
    try {
      const url = `https://dashboard.fouzan.digisolapps.com/api/AddFavItems/${favId}`;
      const urlRemove = `https://dashboard.fouzan.digisolapps.com/api/RemoveFavItems/${favId}`;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      if (!token) {
        console.log('No token found, navigating to login page...');
        navigate('/SignIn-SignUp');
        return;
      }

      if (isFav === 1) {
        const response = await fetch(urlRemove, {
          method: 'DELETE',
          headers,
        });

        if (response.ok) {
          setFav(0);
          toast.info('Product Removed From Favourites', {
            position: 'bottom-left',
          });
        }
      } else {
        const response = await fetch(url, {
          method: 'POST',
          headers,
        });

        if (response.ok) {
          setFav(1);
          toast.success('Product Added To Favourites', {
            position: 'bottom-left',
          });
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <div
      to={'/ItemPage/' + product.item_id}
      className={style.itemContainerProduct}
      key={product.item_id}
    >
      <CiHeart
        fontSize="30px"
        style={{ color: isFav === 1 ? 'red' : 'inherit' }}
        className={style.addToFavBtn}
        onClick={() => handleFavoriteClick(product.item_id, isFav)}
      />
      <img src={salePrice} alt="sale price" className={style.salePrice} />
      <NavLink to={'/ItemPage/' + product.item_id}>
        <img src={product.item_image} alt="item" className={style.itemImage} />
      </NavLink>

      <NavLink to={'/ItemPage/' + product.item_id}>
        <h3 className={style.itemName}>{product.item_name}</h3>
      </NavLink>
      <h3 className={style.itemName}>
        {product.Discount_price ? product.item_price : product.Discount_price}{' '}
        SAR
      </h3>

      <button
        className={style.addToCartBtn}
        onClick={() => handleAddToCart(product)}
      >
        {t('AddToCart')}
      </button>
    </div>
  );
};

export default FeaturedProducts;
