/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-loading-skeleton/dist/skeleton.css'

import { CiHeart } from 'react-icons/ci'
import salePrice from '../../Images/saleImage.png'

import style from './items.module.scss'
import { addToCart } from '../../slices/cartSlice'
import uuid from 'react-uuid'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material'

const ItemsProducts = ({
  key,
  id,
  product,
  productName,
  disCountPrice,
  item_price,
  itemImage,
  fav,
}) => {
  const productsData = useSelector((state) => state.products)
  const dispatch = useDispatch()
  let navigate = useNavigate()

  useMemo(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsData])

  const [token, setToken] = useState()

  useMemo(() => {
    setToken(window.localStorage.getItem('userToken'))
  }, [])

  const [isFav, setFav] = useState(fav)

  const [skills, setSkills] = useState([])
  const [disa, setDisa] = useState()
  const [pricesList, setPricesList] = useState([])
  const [selectedRadio, setSelectedRadio] = useState()
  console.log(selectedRadio)

  const handelSkillsChange = (e) => {
    const index = skills.indexOf(e.target.value)

    if (index === -1) {
      setSkills([...skills, e.target.value])
      setDisa({
        ...disa,
        [e.currentTarget.dataset.group]: e.target.value,
      })

      setPricesList({
        ...pricesList,
        [e.target.value]: e.currentTarget.dataset.price,
      })
    } else {
      setSkills([...skills.filter((skill) => skill !== e.target.value)])
      delete pricesList[e.target.value]
      delete disa[e.currentTarget.dataset.group]
    }
  }

  const handleRadioChange = (event) => {
    console.log(event.target.value)
    setSelectedRadio(event.target.value)
    setSkills([...skills, event.target.value])
  }
  const values = Object.values(pricesList)

  parseInt(values)
  const sum = values.reduce((accumulator, value) => {
    return accumulator + parseFloat(value)
  }, 0)

  const handleAddToCart = (product) => {
    if (token != null || undefined) {
      const totalPrice = Object.values(pricesList).reduce(
        (accumulator, value) => accumulator + parseFloat(value),
        0
      )
      dispatch(
        addToCart({
          ...product,
          item_id: parseInt(id),
          adds: skills,
          orderId: uuid(),
          addsTotal: totalPrice,
        })
      )
    } else {
      navigate('/SignIn-SignUp')
    }
  }

  const handleFavoriteClick = async (favId, isFav) => {
    try {
      const url = `https://dashboard.fouzan.digisolapps.com/api/AddFavItems/${favId}`
      const urlRemove = `https://dashboard.fouzan.digisolapps.com/api/RemoveFavItems/${favId}`
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
      if (!token) {
        console.log('No token found, navigating to login page...')
        navigate('/SignIn-SignUp')
        return
      }

      if (isFav === 1) {
        const response = await fetch(urlRemove, {
          method: 'DELETE',
          headers,
        })

        if (response.ok) {
          setFav(0)
          toast.info('Product Removed From Favourites', {
            position: 'bottom-left',
          })
        }
      } else {
        const response = await fetch(url, {
          method: 'POST',
          headers,
        })

        if (response.ok) {
          setFav(1)
          toast.success('Product Added To Favourites', {
            position: 'bottom-left',
          })
        }
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <div className={style.itemContainerProduct} key={key}>
      <CiHeart
        fontSize="30px"
        style={{ color: isFav === 1 ? 'red' : 'inherit' }}
        className={style.addToFavBtn}
        onClick={() => handleFavoriteClick(id, isFav)}
      />
      {disCountPrice &&
        disCountPrice != 'No Discount' &&
        disCountPrice != item_price && (
          <img src={salePrice} alt="sale price" className={style.salePrice} />
        )}
      <NavLink to={'/ItemPage/' + id}>
        <img src={itemImage} alt="item" className={style.itemImage} />
      </NavLink>

      <NavLink to={'/ItemPage/' + id}>
        <h3 className={style.itemName}>{productName}</h3>
      </NavLink>
      <h3 className={style.itemName}>
        {disCountPrice ? item_price : disCountPrice} SAR
      </h3>

      <button
        className={style.addToCartBtn}
        onClick={() => handleAddToCart(product)}
      >
        Add
      </button>
      {product?.addonscategory != null ? (
        <Box>
          <FormControl>
            {product?.addonscategory.map((data) => (
              <div>
                <FormLabel>{data.category_add_ons_name}</FormLabel>
                <FormGroup row>
                  {data.max > 1 ? (
                    <>
                      {data?.options.map((d, inId) => (
                        <FormControlLabel
                          key={inId}
                          label={`${d.optionName} (${parseFloat(d.price)} SAR)`}
                          control={
                            <input
                              className="checkBoxInput"
                              type="checkbox"
                              value={String(d?.id)}
                              data-price={d?.price}
                              data-group={data.category_add_ons_name + inId}
                              onChange={() => handelSkillsChange}
                              size="small"
                              color="success"
                              name={d.optionName}
                              disabled={
                                !skills?.includes(String(d?.id)) &&
                                skills.length === 100
                              }
                              checked={skills?.includes(String(d?.id))}
                            />
                          }
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {data?.options.map((d, inId) => (
                        <label key={d.id}>
                          <input
                            type="radio"
                            name={data.category_add_ons_name}
                            value={d.id}
                            onChange={() => handleRadioChange}
                          />
                          {d.optionName}
                        </label>
                      ))}
                    </>
                  )}
                </FormGroup>
              </div>
            ))}
          </FormControl>
        </Box>
      ) : (
        ''
      )}
    </div>
  )
}

export default React.memo(ItemsProducts)
