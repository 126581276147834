export const TRANSLATIONS_EN = {
  home: 'Home',
  branches: 'Branches',
  posts: 'Posts',
  products: 'Our Products',
  favorites: 'My Favorites',
  contact: 'Contact Us',
  sales: 'Sales',
  vision: 'Vision',
  mission: 'Mission',
  values: 'Values',
  clearCart: 'Clear Cart',
  Total: 'Total',
  Checkout: 'Check out',
  ContinueShopping: 'Continue Shopping',
  Remove: 'ٌRemove',
  StartShopping: 'Start Shopping',
  emptyCart: 'Your cart is currently empty  😕',
  CATEGORIES: 'CATEGORIES',
  FEATUREDPRODUCTS: 'FEATURED PRODUCTS',
  AddToCart: 'Add To Cart',
  SAR: 'SAR',
  emptyFva: "You Don't have any favourites items yet ✨",
  Stores: 'Stores',
  StoeName: 'Stoe Name',
  TEL: 'TEL',
  removeFromFav: 'Removed From Favourit',
  AddedToFavourit: 'Added To Favourit',
  submit: 'submit',
  name: 'Name',
  email: 'Email',
  subject: 'Subject',
  message: 'Message',
  Wanttoknowmore: 'Want to know more?',
  postsCard: 'There is no posts yet 📮',
  emptyOrders: 'You have not placed any order yet 📦',
  DeleveriyMethod: 'Deleveriy Method',
  PaymentMethod: 'Payment Method',
  OrderId: 'Order Id',
  Item: 'Item',
  ItemName: 'Item Name',
  ItemPrice: 'Item Price',
  Quantity: 'Quantity',
  DeleveriyFee: 'Deleveriy Fee',
  TotalQuantity: 'Total Quantity',
  BranchName: 'Branch Name',
  Tax: 'Tax',
  TotalPrice: 'Total Price',
  Delevered: 'Delevered',
  Rejected: 'Rejected',
  Pending: 'Pending',
  Accepted: 'Accepted',
  Prepare: 'Prepare',
  OnDelevery: 'On Delevery',
  Product: 'Product',
  Price: 'Price',
  sentOrder: 'Your order has been sent 🎉',
  ThankYou: 'Thank You',
  sorry: 'Sorry',
  orderEroor: 'Your order has not been sent 😕',
  ShoppingCart: 'Shopping Cart',
  selectBranch: 'select Branch',
  Delivery: 'Delivery',
  typeofDelivery: 'Type of Delivery',
  quantity_not_available: 'quantity not available',
  not_available_in_stock: 'not available in stock',
  you_must_select_adds: 'You Must Select AddOns',
  newـarrivals: 'New Arrivals',
  brands: 'Brands',
}
