import React, { useEffect, useState } from 'react'
import Iframe from 'react-iframe';

const GoogleMap = () => {


    const [lat, setLat] = useState()
    const [lon, setLon] = useState()
    navigator.geolocation.getCurrentPosition(pos => {
        console.log('Retrieved user location', pos);
        success(pos)
    })


    useEffect(() => {
    }, [lat, lon])


    function success(pos) {
        const crd = pos.coords;
        setLat(crd.latitude)
        setLon(crd.longitude)
        window.localStorage.setItem("latitude", crd.latitude)
        window.localStorage.setItem("longitude", crd.longitude)
    }



    const getData = () => {
        navigator.permissions.query({ name: 'geolocation' })
            .then((result) => {
                if (result.state === 'granted') {
                    navigator.geolocation.getCurrentPosition(pos => {
                        console.log('Retrieved user location', pos);
                        success(pos)

                    }, (error) => {
                        alert('System/OS services disabled', navigator);
                        noLocationFound();
                    }, navigatorLocationOptions);

                } else {

                    alert('Browser location services disabled', navigator);
                    noLocationFound();
                }
            }, (error) => {
                noLocationFound()
            }
            );

        const navigatorLocationOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60000,
            draggable: true
        };



        

    }

    function noLocationFound(err) {
        alert('No location found')
    }

    return (
        <div>
            <Iframe
                className='map-containerTwo'
                frameborder="4"
                src={`https://www.google.com/maps/embed/v1/place?q=${lat},${lon}&zoom=10&key=AIzaSyBx1N_3qK4ZOBs3QL3z-KVl6P-sF8ehLVs`}
                target="_parent"
            >          
            </Iframe>
            <button onClick={getData}>Get Data</button>
        </div>
    )
}

export default GoogleMap