import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

var token = window.localStorage.getItem('userToken')
var lang = window.localStorage.getItem('lang')

var baseUrl = 'https://dashboard.fouzan.digisolapps.com/api'

export const productsFetch = createAsyncThunk(
  'products/productsFetch',

  async (id = 1) => {
    try {
      const response = await axios.get(
        `${baseUrl}/${lang}/getItemWithCategory/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
)

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    items: [],
    status: null,
  },
  reducers: {},
  extraReducers: {
    [productsFetch.fulfilled]: (state, actions) => {
      state.items = actions.payload
      state.status = 'success'
    },
    [productsFetch.pending]: (state, actions) => {
      state.status = 'pending'
    },

    [productsFetch.rejected]: (state) => {
      state.status = 'rejected'
    },
  },
})

export default productsSlice.reducer
