/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import './Products.scss'
import ItemsProducts from './ItemsProducts'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import glasses from '../../Images/glasses.jpg'
import Loader from '../Loader'
import style from './items.module.scss'
import { productsFetch } from '../../slices/productsSlice'
import useQuery from '../../hooks/use-query'

const Products = (props) => {
  let query = useQuery()
  let queryActiveCategory = query.get('category')
  let queryActiveSubCategory = query.get('subCategory')

  let catApi = props.api

  var lang = window.localStorage.getItem('lang') ?? 'ar'

  const [category, setCategory] = useState()
  const [activeCategoryId, setActiveCategoryId] = useState(
    queryActiveCategory ?? 1
  )
  const [activeSubCategoryId, setActiveSubCategoryId] = useState(
    queryActiveSubCategory
  )

  useEffect(() => {
    setActiveCategoryId(queryActiveCategory)
  }, [queryActiveCategory])

  useEffect(() => {
    setActiveSubCategoryId(queryActiveSubCategory)
  }, [queryActiveSubCategory])

  const getCategory = async () => {
    const response = await axios.get(catApi)
    const data = await response.data
    setCategory(data)
  }

  useEffect(() => {
    getCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryActiveCategory])

  const categoryClick = (id) => {
    if (activeCategoryId === id) {
      return
    } else {
      setActiveCategoryId(id)
    }
  }

  const subCategoryClick = (id) => {
    if (activeSubCategoryId === id) {
      return
    } else {
      setActiveSubCategoryId(id)
    }
  }

  const dispatch = useDispatch()

  const productsData = useSelector((state) => state.products)
  useEffect(() => {
    dispatch(productsFetch(activeCategoryId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategoryId, queryActiveCategory])

  const [subCats, setSubCats] = useState()
  useEffect(() => {
    const endpoint = `https://dashboard.fouzan.digisolapps.com/api/category/subcategory?lang=en&category_id=${activeCategoryId}`
    axios
      .get(endpoint)
      .then((res) => res.data.data)
      .then((data) => setSubCats(data))
  }, [activeCategoryId, queryActiveCategory])

  const [subCatData, setSubCatData] = useState()
  useEffect(() => {
    const endpoint = `https://dashboard.fouzan.digisolapps.com/api/subcategory/items?lang=ar&subcategory_id=${activeSubCategoryId}&category_id=${activeCategoryId}`
    axios
      .get(endpoint)
      .then((res) => res.data.data)
      .then((data) => setSubCatData(data))
  }, [activeSubCategoryId, activeCategoryId])

  return (
    <div className="productsContainerId">
      <Loader />

      <img src={glasses} alt="instyle" className="glassesPoductsImage" />
      <ul id="catigoresList">
        {category?.map((category, i) => (
          <li
            key={i}
            value={category.id}
            onClick={() => categoryClick(category.id)}
            className={
              activeCategoryId == category.id
                ? 'activeCategory'
                : 'notActiveCategory'
            }
            style={
              activeCategoryId == category.id
                ? { color: 'white' }
                : { color: 'black' }
            }
            disabled={activeCategoryId == category.id}
          >
            {category.category_name}
          </li>
        ))}
      </ul>

      <ul id="catigoresList">
        {subCats?.map((subCat) => (
          <li
            key={subCat.id}
            value={subCat.id}
            onClick={() => subCategoryClick(subCat.id)}
            className={
              activeSubCategoryId == subCat.id
                ? 'activeCategory'
                : 'notActiveCategory'
            }
            style={
              activeSubCategoryId == subCat.id
                ? { color: 'white' }
                : { color: 'black' }
            }
            disabled={activeSubCategoryId == subCat.id}
          >
            {lang === 'ar' ? subCat.category_name_ar : subCat.category_name_en}
          </li>
        ))}
      </ul>

      <div className={style.productsContainer}>
        {(subCatData ?? productsData.items)?.map(function (product, index) {
          return (
            <ItemsProducts
              key={index}
              id={product.id}
              product={product}
              productName={product.item_name}
              disCountPrice={product.Discount_price}
              itemImage={product.item_image}
              fav={product.fav_item}
              item_price={product.item_price}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Products
